/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-18",
    versionChannel: "nightly",
    buildDate: "2024-08-18T00:05:54.699Z",
    commitHash: "e1c05da6186675d450bafadd8dc9a6218507d54d",
};
